import React, { useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const QueryForm = () => {
    const [query, setQuery] = useState('');
    const [responseText, setResponseText] = useState('');
    const [base64Image, setBase64Image] = useState('');
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://insights-ga-agent-backend.lab.neuralcompany.team/morepan/', { query });
            const { text, base64_image, error, chartData: responseChartData } = response.data;
            // setResponseText(response.data.text);

            if (error) {
                setResponseText(error);
                setBase64Image('');
                setChartData({ labels: [], datasets: [] });
                return;
            }

            setResponseText(text);
            setBase64Image(base64_image);
            
            // Example of setting chart data if available. Modify as per your response structure.
            if (responseChartData) {
                setChartData({
                    labels: responseChartData.labels || [],
                    datasets: [
                        {
                            label: 'Sample Data',
                            data: responseChartData.values || [],
                            borderColor: 'rgba(75, 192, 192, 1)',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderWidth: 1
                        }
                    ]
                });
            } else {
                setChartData({ labels: [], datasets: [] });
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="query-form-container">
            <h2>Submit Your Query</h2>
            <form onSubmit={handleSubmit}>
                <textarea
                    name="query"
                    placeholder="Enter your query here..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    required
                />
                <button type="submit">Submit</button>
            </form>

            {base64Image && (
                <div className="image-container">
                    <h3>Image:</h3>
                    <img src={`data:image/png;base64,${base64Image}`} alt="Response" className="response-image" />
                </div>
            )}

            {responseText &&(
                <div className="response-container">
                    <h3>Answer:</h3>
                    <textarea
                        className="response-textbox"
                        value={responseText}
                        readOnly
                    />
                </div>
            )}

            {chartData.labels.length > 0 && (
                <div className="chart-container">
                    <h3>Data Visualization:</h3>
                    <Line data={chartData} />
                </div>
            )}
        </div>
    );
};

export default QueryForm;


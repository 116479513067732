import React, { useState } from 'react';
import axios from 'axios';

const DatabaseConnectionForm = () => {
    const [formData, setFormData] = useState({
        host: '', port: '', database: '', username: '', password: '', table: '', databasetype: '', action: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://insights-ga-agent-backend.lab.neuralcompany.team/database-connection', formData);
            alert(response.data.message);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="form-container">
            <h2>Database Connection</h2>
            <form onSubmit={handleSubmit}>
                <input type="text" name="host" placeholder="Host" value={formData.host} onChange={handleChange} required />
                <input type="number" name="port" placeholder="Port" value={formData.port} onChange={handleChange} required />
                <input type="text" name="database" placeholder="Database" value={formData.database} onChange={handleChange} required />
                <input type="text" name="username" placeholder="Username" value={formData.username} onChange={handleChange} required />
                <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
                <input type="text" name="table" placeholder="Table" value={formData.table} onChange={handleChange} required />
                <select name="databasetype" value={formData.databasetype} onChange={handleChange} required>
                    <option value="">Select Database Type</option>
                    <option value="postgres">PostgreSQL</option>
                    <option value="mysql">MySQL</option>
                    <option value="generic">Generic</option>
                </select>
                <button type="submit">Connect</button>
            </form>
        </div>
    );
};

export default DatabaseConnectionForm;










import React from 'react';
import DatabaseConnectionForm from './components/DatabaseConnectionForm';
import QueryForm from './components/QueryForm';
import './App.css';
import './index.css';


const App = () => {
    return (
        <div className="app-container">
            {/* Sidebar */}
            <div className="sidebar">
                <div className="logo-container">
                    <img src="/assets/xenonstack_logo.png" alt="Company Logo" className="company-logo" />
                </div>
                <DatabaseConnectionForm />
            </div>

            {/* Main content */}
            <div className="main-content">
                <QueryForm />
            </div>
        </div>
    );
};

export default App;


